<template>
  <div id="app">
     <vue-snotify></vue-snotify>
    <router-view />
  </div>
</template>

<script>
import appConfig from "@/app.config";
import Snotify, { SnotifyPosition } from 'vue-snotify'
import 'vue-snotify/styles/material.css'
import Vue from 'vue';


const options = {
  toast: {
    position: SnotifyPosition.rightBottom
  }
}
Vue.use(Snotify,options);
export default {
  name: "app",
  page: {
    // All subcomponent titles will be injected into this template.
    titleTemplate(title) {
      title = typeof title === "function" ? title(this.$store) : title;
      return title ? `${title} | ${appConfig.title}` : appConfig.title;
    }
  }
};
</script>