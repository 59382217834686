import store from '@/state/store'

export default [
    {
        path: '/login',
        name: 'login',
        component: () => import('../views/pages/account/login'),
        meta: {
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                if (store.getters['auth/loggedIn']) {
                    // Redirect to the home page instead
                    next({ name: 'home' })
                } else {
                    // Continue to the login page
                    next()
                }
            },
        },
    },
    {
        path: '/register',
        name: 'register',
        component: () => import('../views/pages/account/register'),
        /*meta: {
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                if (store.getters['auth/loggedIn']) {
                    // Redirect to the home page instead
                    next({ name: 'home' })
                } else {
                    // Continue to the login page
                    next()
                }
            },
        },*/
    },
    {
        path: '/forgot-password',
        name: 'Forgot-password',
        component: () => import('../views/pages/account/forgot-password'),
        /*meta: {
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                if (store.getters['auth/loggedIn']) {
                    // Redirect to the home page instead
                    next({ name: 'home' })
                } else {
                    // Continue to the login page
                    next()
                }
            },
        },*/
    },
    {
        path: '/logout',
        name: 'logout',
        meta: {
            authRequired: false,
            beforeResolve(routeTo, routeFrom, next) {
                if (process.env.VUE_APP_DEFAULT_AUTH === "firebase") {
                    store.dispatch('auth/logOut')
                } else {
                    store.dispatch('authfack/logout')
                }
                const authRequiredOnPreviousRoute = routeFrom.matched.some(
                    (route) => route.push('/login')
                )
                // Navigate back to previous page, or home as a fallback
                next(authRequiredOnPreviousRoute ? { name: 'home' } : { ...routeFrom })
            },
        },
    },
    {
        path: '/',
        name: 'home',
        meta: {
            authRequired: false,
        },
        component: () => import('../views/pages/dashboard/index')
    },
    {
        path: '/message/nouveau',
        name: 'Nouveau Message',
        meta: {
            authRequired: false,
        },
        component: () => import('../views/pages/message/new')
    },
    {
        path: '/message/historique',
        name: 'Historique Message',
        meta: {
            authRequired: false,
        },
        component: () => import('../views/pages/message/historique')
    },










    /*{
        path: '/chat',
        name: 'Chat',
        meta: {
            authRequired: false,
        },
        component: () => import('../views/pages/chat/index')
    },
    {
        path: '/calendar',
        name: 'Calendar',
        meta: {
            authRequired: false,
        },
        component: () => import('../views/pages/calendar/index')
    },
    {
        path: '/ecommerce/products',
        name: 'products',
        meta: { authRequired: false },
        component: () => import('../views/pages/ecommerce/products')
    },
    {
        path: '/ecommerce/product-detail',
        name: 'product detail',
        meta: { authRequired: false },
        component: () => import('../views/pages/ecommerce/product-detail')
    },
    {
        path: '/ecommerce/orders',
        name: 'Orders',
        meta: { authRequired: false },
        component: () => import('../views/pages/ecommerce/orders')
    },
    {
        path: '/ecommerce/customers',
        name: 'Customers',
        meta: { authRequired: false },
        component: () => import('../views/pages/ecommerce/customers')
    },
    {
        path: '/ecommerce/cart',
        name: 'Cart',
        meta: { authRequired: false },
        component: () => import('../views/pages/ecommerce/cart')
    },
    {
        path: '/ecommerce/checkout',
        name: 'Checkout',
        meta: { authRequired: false },
        component: () => import('../views/pages/ecommerce/checkout')
    },
    {
        path: '/ecommerce/shops',
        name: 'Shops',
        meta: { authRequired: false },
        component: () => import('../views/pages/ecommerce/shops')
    },
    {
        path: '/ecommerce/add-product',
        name: 'Add-product',
        meta: { authRequired: false },
        component: () => import('../views/pages/ecommerce/add-product')
    },
    {
        path: '/email/inbox',
        name: 'Inbox',
        meta: { authRequired: false },
        component: () => import('../views/pages/email/inbox')
    },
    {
        path: '/email/read',
        name: 'Read Email',
        meta: { authRequired: false },
        component: () => import('../views/pages/email/reademail')
    },*/
    {
        path: '/auth/login-1',
        name: 'login-1',
        meta: { authRequired: false },
        component: () => import('../views/pages/sample-auth/login-1')
    },
    {
        path: '/auth/register-1',
        name: 'register-1',
        meta: { authRequired: false },
        component: () => import('../views/pages/sample-auth/register-1')
    },
    {
        path: '/auth/lock-screen-1',
        name: 'Lock-screen-1',
        meta: { authRequired: false },
        component: () => import('../views/pages/sample-auth/lock-screen-1')
    },
    {
        path: '/auth/recoverpwd-1',
        name: 'Recoverpwd-1',
        meta: { authRequired: false },
        component: () => import('../views/pages/sample-auth/recoverpwd-1')
    },
    /*{
        path: '/apps/kanban-board',
        name: 'Kanban-board',
        meta: { authRequired: false },
        component: () => import('../views/pages/kanbanboard/index')
    },
    {
        path: '/pages/starter',
        name: 'Starter Page',
        meta: { authRequired: false },
        component: () => import('../views/pages/utility/starter')
    },
    {
        path: '/pages/maintenance',
        name: 'Maintenance',
        meta: { authRequired: false },
        component: () => import('../views/pages/utility/maintenance')
    },
    {
        path: '/pages/coming-soon',
        name: 'Coming-soon',
        meta: { authRequired: false },
        component: () => import('../views/pages/utility/coming-soon')
    },
    {
        path: '/pages/timeline',
        name: 'Timeline',
        meta: { authRequired: false },
        component: () => import('../views/pages/utility/timeline/index')
    },
    {
        path: '/pages/faqs',
        name: 'Faqs',
        meta: { authRequired: false },
        component: () => import('../views/pages/utility/faqs')
    },
    {
        path: '/pages/pricing',
        name: 'Pricing',
        meta: { authRequired: false },
        component: () => import('../views/pages/utility/pricing/index')
    },
    {
        path: '/pages/error-404',
        name: 'Error-404',
        meta: { authRequired: false },
        component: () => import('../views/pages/utility/error-404')
    },
    {
        path: '/pages/error-500',
        name: 'Error-500',
        meta: { authRequired: false },
        component: () => import('../views/pages/utility/error-500')
    },
    {
        path: '/icons/font-awesome',
        name: 'Font Awesome 5',
        meta: { authRequired: false },
        component: () => import('../views/pages/icons/font-awesome/index')
    },
    {
        path: '/icons/dripicons',
        name: 'Dripicons',
        meta: { authRequired: false },
        component: () => import('../views/pages/icons/dripicons')
    },
    {
        path: '/icons/material-design',
        name: 'Material Design',
        meta: { authRequired: false },
        component: () => import('../views/pages/icons/materialdesign/index')
    },
    {
        path: '/icons/remix',
        name: 'Remix Icons',
        meta: { authRequired: false },
        component: () => import('../views/pages/icons/remix/index')
    },
    {
        path: '/ui/buttons',
        name: 'Buttons',
        meta: { authRequired: false },
        component: () => import('../views/pages/ui/buttons')
    },
    {
        path: '/ui/alerts',
        name: 'Alerts',
        meta: { authRequired: false },
        component: () => import('../views/pages/ui/alerts')
    },
    {
        path: '/ui/grid',
        name: 'Grid',
        meta: { authRequired: false },
        component: () => import('../views/pages/ui/grid')
    },
    {
        path: '/ui/cards',
        name: 'Cards',
        meta: { authRequired: false },
        component: () => import('../views/pages/ui/cards')
    },
    {
        path: '/ui/carousel',
        name: 'Carousel',
        meta: { authRequired: false },
        component: () => import('../views/pages/ui/carousel')
    },
    {
        path: '/ui/dropdowns',
        name: 'Dropdowns',
        meta: { authRequired: false },
        component: () => import('../views/pages/ui/dropdowns')
    },
    {
        path: '/ui/images',
        name: 'Images',
        meta: { authRequired: false },
        component: () => import('../views/pages/ui/images')
    },
    {
        path: '/ui/modals',
        name: 'Modals',
        meta: { authRequired: false },
        component: () => import('../views/pages/ui/modals')
    },
    {
        path: '/ui/rangeslider',
        name: 'Range - slider',
        meta: { authRequired: false },
        component: () => import('../views/pages/ui/rangeslider')
    },
    {
        path: '/ui/progressbar',
        name: 'Progressbar',
        meta: { authRequired: false },
        component: () => import('../views/pages/ui/progressbars')
    },
    {
        path: '/ui/sweet-alert',
        name: 'Sweet-alert',
        meta: { authRequired: false },
        component: () => import('../views/pages/ui/sweet-alert')
    },
    {
        path: '/ui/tabs-accordion',
        name: 'Tabs & Accordion',
        meta: { authRequired: false },
        component: () => import('../views/pages/ui/tabs-accordions')
    },
    {
        path: '/ui/typography',
        name: 'Typography',
        meta: { authRequired: false },
        component: () => import('../views/pages/ui/typography')
    },
    {
        path: '/ui/video',
        name: 'Video',
        meta: { authRequired: false },
        component: () => import('../views/pages/ui/video')
    },
    {
        path: '/ui/general',
        name: 'General',
        meta: { authRequired: false },
        component: () => import('../views/pages/ui/general')
    },
    {
        path: '/ui/lightbox',
        name: 'Lightbox',
        meta: { authRequired: false },
        component: () => import('../views/pages/ui/lightbox')
    },
    {
        path: '/ui/notification',
        name: 'Notification',
        meta: { authRequired: false },
        component: () => import('../views/pages/ui/notification')
    },
    {
        path: '/ui/rating',
        name: 'Rating',
        meta: { authRequired: false },
        component: () => import('../views/pages/ui/rating')
    },
    {
        path: '/ui/session-timeout',
        name: 'Session Timeout',
        meta: { authRequired: false },
        component: () => import('../views/pages/ui/session-timeout')
    },
    {
        path: '/form/elements',
        name: 'Form Elements',
        meta: { authRequired: false },
        component: () => import('../views/pages/forms/elements')
    },*/
    //user
    {
        path: '/user/add_users',
        name: 'Nouveau utilisateur',
        meta: { authRequired: false },
        component: () => import('../views/pages/users/addUser')
    },
    {
        path: '/user/users_list',
        name: 'Liste utilisateur',
        meta: { authRequired: false },
        component: () => import('../views/pages/users/UserList')
    },
    {
        path: '/user/suspended_users',
        name: 'Liste utilisateurs suspendus',
        meta: { authRequired: false },
        component: () => import('../views/pages/users/suspendu')
    },
    {
        path: '/user/user_info/:idUser',
        name: 'Agence info',
        meta: { authRequired: false },
        props: route=>({
            idUser: route.params["idUser"]
        }),
        component: () => import('../views/pages/users/userinfo')
    },
    {
        path: '/agence/details/:row',
        name: 'Agence détails',
        meta: { authRequired: false },
        props: route=>({
            idUser: route.params["row"]
        }),
        component: () => import('../views/pages/agence/details')
    },
    {
        path: '/vehicule/historique/:row',
        name: 'Historique de locations',
        meta: { authRequired: false },
        props: route=>({
            idUser: route.params["row"]
        }),
        component: () => import('../views/pages/vehicule/historique')
    },
    {
        path: '/user/verification/:idUser',
        name: 'Vérification utilisateur',
        meta: { authRequired: false },
        props: route=>({
            idUser: route.params["idUser"]
        }),
        component: () => import('../views/pages/users/verification')
    },
    //fin user

    //Réservation
    {
        path: '/transaction/remboursement',
        name: 'Remboursement',
        meta: { authRequired: false },
        component: () => import('../views/pages/transaction/remboursement')
    },
    {
        path: '/transaction/virement',
        name: 'Réservations',
        meta: { authRequired: false },
        component: () => import('../views/pages/transaction/virement')
    },
    // Fin réservation

    // remboursement
    {
        path: '/remboursement/historique',
        name: 'Réservation',
        meta: { authRequired: false },
        component: () => import('../views/pages/remboursement/historique')
    },

    //admin
    {
        path: '/admin/add-admin',
        name: 'Administrateur',
        meta: { authRequired: false },
        component: () => import('../views/pages/administrateur/add_admin')
    },
    //fin admin


    // transaction 
   /*  {
        path: '/transactions/historique-transactions',
        name: 'Transactions',
        meta: { authRequired: false },
        component: () => import('../views/pages/transactions/historique')
    }, */
    // fin transaction
    
    //virement
    {
        path: '/virements/historique-virements',
        name: 'Virement',
        meta: { authRequired: false },
        component: () => import('../views/pages/virements/historique')
    },
    //fin virement

    // paiement
    {
        path: '/suivis/historique',
        name: 'Suivis de gains',
        meta: { authRequired: false },
        component: () => import('../views/pages/suivis-gains/historique')
    },
    //fin paiment


    //statistique utilisateur
    {
        path: '/user/statistique',
        name: 'Statistiques',
        meta: { authRequired: false },
        component: () => import('../views/pages/users/statistique')
    },
    //fin statistique

    // badge
    {
        path: '/user/badge',
        name: 'Statistique',
        meta: { authRequired: false },
        component: () => import('../views/pages/users/badge')
    },
    // fin badge

    //details trajet_a_venir
    /* {
        path: '/trajets/details_avenir/:idtrajet',
        name: 'details trajets à venir  ',
        meta: { authRequired: false },
        props: route=>({
            idtrajet: route.params["idtrajet"]
        }),
        component: () => import('../views/pages/trajets/details_avenir')
    },
    {
        path: '/trajets/details_effectues/:idtrajet',
        name: 'details trajets effectués',
        meta: { authRequired: false },
        props: route=>({
            idtrajet: route.params["idtrajet"]
        }),
        component: () => import('../views/pages/trajets/details_effectues')
    },
    {
        path: '/trajets/details_encours/:idtrajet',
        name: 'details trajets en cours',
        meta: { authRequired: false },
        props: route=>({
            idtrajet: route.params["idtrajet"]
        }),
        component: () => import('../views/pages/trajets/details_encours')
    },
    {
        path: '/trajets/details_annules/:idtrajet',
        name: 'details trajets annulés',
        meta: { authRequired: false },
        props: route=>({
            idtrajet: route.params["idtrajet"]
        }),
        component: () => import('../views/pages/trajets/details_annules')
    },
    {
        path: '/trajets/urgences',
        name: 'urgences',
        meta: { authRequired: false },
        component: () => import('../views/pages/trajets/urgences')
    },
    //fin details trajets_a_venir */

    //Historique des locations
    
    
    //Agence
    {
        path:'/agence/list',
        name:'liste',
        meta:{ authRequired: false},
        component: () => import('../views/pages/agence/list')
    },
    {
        path:'/agence/list_utilisateur',
        name:'utilisateur_rmobility',
        meta:{ authRequired: false},
        component: () => import('../views/pages/agence/utilisateur_rmobility')
    },

    //Demande en attente
    {
        path:'/demande/demandes_particulier',
        name:'Demandes des particulier',
        meta:{ authRequired: false},
        component: () => import('../views/pages/demande/demandes_particulier')
    },
    {
        path:'/demande/list',
        name:'liste',
        meta:{ authRequired: false},
        component: () => import('../views/pages/demande/liste')
    },

    // Abonnement
    {
        path:'/abonnement/historique',
        name:'liste',
        meta:{ authRequired: false},
        component: () => import('../views/pages/abonnement/historique')
    },
    {
        path:'/abonnement/list',
        name:'liste',
        meta:{ authRequired: false},
        component: () => import('../views/pages/abonnement/list')
    },

    // Suivi/Entretien
    {
        path:'/suivi/reparation',
        name:'liste',
        meta:{ authRequired: false},
        component: () => import('../views/pages/suivi-entretien/reparation')
    },
    {
        path:'/suivi/entretien',
        name:'liste',
        meta:{ authRequired: false},
        component: () => import('../views/pages/suivi-entretien/suivi')
    },


    /*{
        path:'/form/add_user',
        name:'Form user',
        meta: { authRequired: false},
        component: () => import('../views/pages/forms/adduser')
    },
    {
        path: '/form/users_list',
        name: 'Form Advanced',
        meta: { authRequired: false },
        component: () => import('../views/pages/forms/UsersList')
    },
    {
        path: '/form/editor',
        name: 'CK Editor',
        meta: { authRequired: false },
        component: () => import('../views/pages/forms/ckeditor')
    },
    {
        path: '/form/uploads',
        name: 'File Uploads',
        meta: { authRequired: false },
        component: () => import('../views/pages/forms/uploads')
    },
    {
        path: '/form/wizard',
        name: 'Form Wizard',
        meta: { authRequired: false },
        component: () => import('../views/pages/forms/wizard')
    },
    {
        path: '/form/mask',
        name: 'Form Mask',
        meta: { authRequired: false },
        component: () => import('../views/pages/forms/mask')
    },
    {
        path: '/tables/basic',
        name: 'Basic Tables',
        meta: { authRequired: false },
        component: () => import('../views/pages/tables/basictable')
    },
    {
        path: '/tables/advanced',
        name: 'Advanced Tables',
        meta: { authRequired: false },
        component: () => import('../views/pages/tables/advancedtable')
    },
    {
        path: '/charts/apex',
        name: 'Apex chart',
        meta: { authRequired: false },
        component: () => import('../views/pages/charts/apex')
    },
    {
        path: '/charts/chartjs',
        name: 'Chartjs chart',
        meta: { authRequired: false },
        component: () => import('../views/pages/charts/chartjs/index')
    },
    {
        path: '/charts/chartist',
        name: 'Chartist chart',
        meta: { authRequired: false },
        component: () => import('../views/pages/charts/chartist')
    },
    {
        path: '/charts/echart',
        name: 'Echart chart',
        meta: { authRequired: false },
        component: () => import('../views/pages/charts/echart/index')
    },
    {
        path: '/maps/google',
        name: 'Google Maps',
        meta: { authRequired: false },
        component: () => import('../views/pages/maps/google')
    },
    {
        path: '/maps/leaflet',
        name: 'Leaflet Maps',
        meta: { authRequired: false },
        component: () => import('../views/pages/maps/leaflet/index')
    },*/
]
